import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { Fragment, useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap'

enum ModalType {
  ALERT,
  PROMPT,
}

enum ModalState {
  CLOSE,
  OPEN,
}

export type ModalTypeEnum = keyof typeof ModalType
export type ModalStateEnum = keyof typeof ModalState

export const defaultModalButton = ['Ya', 'Tidak']

interface IInitialModalState {
  show: boolean
  title: string
  content: React.ReactNode
  button: string[]
  state: ModalStateEnum
  type: ModalTypeEnum
  isLoading: boolean
  action?(): Promise<boolean> | boolean
}

export interface ModalContainer {
  withButton?: boolean
  withToggle?: boolean
  style?: React.CSSProperties
  className?: string
}

export const useModal = () => {
  const [modal, setModal] = useState({
    show: false,
    title: 'Informasi',
    content: '',
    button: defaultModalButton,
    state: 'CLOSE',
    type: 'ALERT',
    isLoading: false,
    action: () => {},
  } as IInitialModalState)

  const dismiss = () => {
    setModal((modal) => ({
      ...modal,
      show: false,
      state: 'CLOSE',
    }))
  }

  const showAlert = ({ title = 'Informasi', content }) => {
    setModal({
      ...modal,
      show: true,
      isLoading: false,
      title,
      content,
      type: 'ALERT',
      state: 'OPEN',
    })
  }

  const showConfirm = ({
    title = 'Konfirmasi',
    content,
    action,
    button = defaultModalButton,
  }) => {
    setModal({
      ...modal,
      show: true,
      isLoading: false,
      title,
      content,
      type: 'PROMPT',
      state: 'OPEN',
      button,
      action,
    })
  }

  const confirm = async () => {
    setModal({ ...modal, isLoading: true })
    try {
      const result = await modal.action()
      if (result) {
        return setModal({ ...modal, state: 'CLOSE', show: false })
      }
      throw new Error('not true')
    } catch (e) {
      setModal({ ...modal, isLoading: false })
    }
  }

  const confirmButton = () => (
    <Fragment>
      <Button color="primary" onClick={confirm} disabled={modal.isLoading}>
        {modal.isLoading && <Spinner size="sm" className="mr-2" />}
        {modal.button[0]}
      </Button>
      <Button
        color="primary"
        outline
        onClick={dismiss}
        disabled={modal.isLoading}
      >
        {modal.button[1]}
      </Button>
    </Fragment>
  )

  const dismissButton = () => {
    return (
      <Button color="primary" onClick={dismiss}>
        Ok
      </Button>
    )
  }

  const ModalContainer: React.FC<ModalContainer> = ({
    withButton = true,
    withToggle = false,
    className,
    ...props
  }) => {
    const toggle = () => dismiss()

    return (
      <Modal
        isOpen={modal.show}
        centered
        className={['modal-global', className].join(' ')}
        {...props}
      >
        <ModalHeader
          toggle={withToggle ? toggle : null}
          className="font-semibold"
          close={
            withToggle && (
              <button
                color=""
                className="close appearance-none text-lg"
                onClick={toggle}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )
          }
        >
          {modal.title}
        </ModalHeader>
        <ModalBody>{modal.content}</ModalBody>
        {withButton && (
          <ModalFooter>
            {modal.type === 'ALERT' && dismissButton()}
            {modal.type === 'PROMPT' && confirmButton()}
          </ModalFooter>
        )}
      </Modal>
    )
  }

  return {
    ModalContainer,
    showAlert,
    showConfirm,
    dismiss,
  }
}
